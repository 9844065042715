<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organization.name }} - {{ $t("ORGANIZATIONS.ACCOUNTS") }}
    </h3>
    <div class="col-12">
        <dl class="row">
            <div class="list-group col-12">
                <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
                    <span slot="title">
                        <span class="icon">
                          <esign-icon class="organization-account-icon" />
                        </span>
                        {{ $t("ORGANIZATIONS.ONESPAN_ACCOUNT") }}
                    </span>
                </a>
                <a href="#" class="list-group-item list-group-item-action">{{ $t("COMMON.API_KEY") }} : {{ organization.onespan_api_key }}</a>
            </div>
        </dl>
    </div>
  </div>
</template>
<script>
import EsignIcon from '@/components/Icons/EsignIcon.vue';
export default {
  components: {
    EsignIcon
  },
  name: "organization-view-global",
  props: ["organization"],
  watch: {
    organization(organization) {},
  },
};
</script>
